.add-professional-back {
  fill: var(--gray800);
  transition: all ease-in-out 0.2s;
  font-size: 1rem;
  display: flex;
  margin-bottom: 1rem;
  width: fit-content;
}

.add-professional-back:hover {
  color: var(--primary);
}

.add-professional-back .back-arrow {
  fill: var(--gray800);
  transition: all ease-in-out 0.2s;
}

.add-professional-back:hover .back-arrow {
  fill: var(--primary);
  color: var(--primary);
}

.add-professional-back span {
  margin: auto 0.5rem;
}

/* .styled-input-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.styled-input-container label {
  margin-bottom: 0.66rem;
  color: var(--gray700) !important;
}

.styled-input-container label b {
  color: var(--red);
} */

.shift-box,
.shift-box-add {
  background-color: var(--primary300);
  padding: 0.3rem;
  border-radius: 0.4rem;
  text-align: center;
  height: 1rem;
  transition: all ease-in-out.2s;
  margin: 0.1rem 0.2rem;
}

.shift-box-add:hover {
  cursor: pointer;
  background: var(--primary400);
}

.number-of-shifts {
  margin-top: 2rem;
  table-layout: fixed;
}

.number-of-shifts th {
  font-weight: normal;
  color: var(--gray600);
}

.delete-shift-box {
  width: 1.2rem;
}

.delete-shift-box path {
  fill: var(--gray600);
  transition: ease-in-out 0.2s;
  cursor: pointer;
}

.delete-shift-box:hover path {
  fill: var(--primary);
}

.value-by-day {
  display: flex;
}


.value-by-day input {
  width: 100%;
}
/*
.value-by-day label {
    width: 100%;
    text-align: center;
} */

.value-radio {
  display: flex;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.value-radio label {
  margin-right: 1rem;
}
