.edit-button {
  border: none;
  outline: none;
  padding: 8px;
  display: flex;
  background: var(--primary100);
  transition: ease-in-out 0.2s;
  cursor: pointer;
}

.edit-button:hover {
  background: var(--primary200);
}

.MuiPaginationItem-text {
  color: var(--gray600) !important;
}

.Mui-selected {
  color: #fff !important;
}

.input-with-icon {
  position: relative;
}

.input-with-icon input {
  padding: 0.75rem 1rem;
  padding-right: 3rem;
}
.input-with-icon .input-icon {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
}

.delete-button {
  border: none;
  outline: none;
  padding: 8px;
  display: flex;
  background: #f8e8e8;
  transition: ease-in-out 0.2s;
  cursor: pointer;
}

.delete-button:hover {
  background: #fdcbcb;
}

.buttons-row {
  justify-content: space-between !important;
}
