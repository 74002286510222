.escalados {
  display: flex;
  margin-top: 1rem;
  gap: 10px;
  width: fit-content;
  max-width: 84%;
}

.escalados-span {
  color: var(--gray700);
}
.escalados .user-escalado {
  background: var(--primary100);
  width: 3rem;
  height: 3rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  border-radius: 100%;
  color: var(--primary);
  font-weight: 700;
  margin-right: -4px;
}

.coworkers-list {
  margin-top: 1.8rem;
}
.coworkers-list .coworker {
  display: flex;
  margin-bottom: 1.6rem;
}

.coworkers-list .coworker .img {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  height: 3rem;
  width: 3rem;
  border-radius: 100%;
  background: var(--primary100);
  margin-right: 0.4rem;
}
.coworkers-list .coworker .info {
  display: flex;
  flex-direction: column;
  padding: 0.3rem;
  flex: 1;
}

.coworkers-list .coworker .info .details {
  margin-top: 0.4rem;
  color: var(--gray500);
}

.h-span {
  margin: auto;
  margin-left: -1.5rem;
  margin-right: 1.1rem;
  color: var(--gray400);
}
