:root {
  --primary: #009ffa;
  --secondary: #d085df;
  --background: #f9fafb;
  --white: #fff;
  --green: #00c9bd;
  --red: #cb4141;
  --redAccent: #ae3434;
  --loginGradient: linear-gradient(100.12deg, #e5eef3 42.43%, #ffffff 55.82%);
  --destructive-bg: #ffe2e2;
  --destructive-bg-hover: #ffcccc;

  --gray100: #f1f1f1;
  --gray200: #d7e3e8;
  --gray300: #c6d4d9;
  --gray400: #b5c3c8;
  --gray500: #9fabaf;
  --gray600: #737b80;
  --gray700: #49575e;
  --gray800: #192b34;
  --fontFamily: 'Inter', sans-serif;

  --primaryAccent: #0094e9;
  --primary700: #1cacff;
  --primary600: #3cb8ff;
  --primary500: #5dc4ff;
  --primary400: #7dd0ff;
  --primary300: #9edcff;
  --primary200: #bee7ff;
  --primary100: #dff3ff;

  --regular: 0.8rem;

  --selected-1: #009ffa;
  --selected-2: #00fac8;
  --selected-3: #fa0060;
  --selected-4: #fa00e9;
  --selected-5: #00fa3a;
  --selected-6: #fad900;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  color: var(--gray800);
  background: #f9fafb;
  font-size: 0.8rem !important;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input {
  font-family: 'Inter', sans-serif !important;
  color: var(--gray800);
}

::placeholder {
  color: var(--gray500);
  opacity: 1; /* Firefox */
}

.edit-button {
  border: none;
  outline: none;
  padding: 8px;
  display: flex;
  background: var(--primary100);
  transition: ease-in-out 0.2s;
  cursor: pointer;
}

.edit-button:hover {
  background: var(--primary200);
}

.delete-button {
  border: none;
  outline: none;
  padding: 8px;
  display: flex;
  background: #f8e8e8;
  transition: ease-in-out 0.2s;
  cursor: pointer;
}

.delete-button:hover {
  background: #fdcbcb;
}

.play-button {
  border: none;
  outline: none;
  padding: 8px;
  display: flex;
  background: #ffead8;
  transition: ease-in-out 0.2s;
  cursor: pointer;
}

.play-button path {
  fill: #f1821c;
}

.play-button:hover {
  background: #ffe0c6;
}

.pause-button {
  border: none;
  outline: none;
  padding: 8px;
  display: flex;
  background: #d0fcf9;
  transition: ease-in-out 0.2s;
  cursor: pointer;
}

.pause-button path {
  fill: var(--green);
}
.pause-button:hover {
  background: #bef6f2;
}

.delete-button,
.edit-button,
.pause-button,
.play-button {
  border-radius: 0.25rem;
}

.delete-button svg,
.edit-button svg,
.pause-button svg,
.play-button svg,
.delete-button img,
.edit-button img,
.pause-button img,
.play-button img {
  height: 1.1rem;
  width: 1.1rem;
}
.select-styled-icon {
  margin-left: -5rem;
  cursor: pointer;
  padding: 0.1rem;
}

.select-styled-icon:hover {
  background-color: var(--gray100);
  border-radius: 100%;
}

.styled-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;

  width: 1rem;
  height: 1rem;
  border: 2px solid var(--gray500); /* Inner border */
  border-radius: 4px;

  transition: 0.2s ease-in-out;
  cursor: pointer;
}

.styled-checkbox:focus-visible {
  outline: none;
}

.styled-checkbox:checked {
  background-color: var(--primary); /* The "check" */
  border: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 120% 120%;
  background-position: center;
}

input[type='date']::-webkit-calendar-picker-indicator {
  opacity: 0.4;
}

input[type=number] {
  -moz-appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
