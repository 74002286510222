.sticky-button {
  padding: 0.5rem;
  border-radius: 100%;
  border: none;
  outline: none;
  background: var(--gray200);
  cursor: pointer;
  transition: all ease-in-out 0.2s;
  position: absolute;
  right: 2rem;
  bottom: 2rem;
}

.sticky-button:hover {
  background: var(--primary300);
}

.sticky-button:hover path {
  fill: var(--primary700);
}
.sticky-button path {
  transition: all ease-in-out 0.2s;
  fill: var(--gray500);
}
.sticky-button svg {
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  transform: rotate(90deg);
}
