.notification-day {
  font-weight: 500;
  color: var(--gray500);
  padding-top: 2rem;
  padding-bottom: 1.5rem;
  border-bottom: solid 1px var(--gray100);
  margin: 0 -2rem;
}

.notification-day span {
  margin-left: 2rem;
}
