.publish-span {
  color: var(--gray500);
  margin-right: 1.5rem;
  margin-top: auto;
  margin-bottom: auto;
}

.publish-row .publish-span:last-of-type {
  margin-left: 1.5rem;
}

.publish-buttons {
  justify-content: end;
}

.publish-buttons button {
  width: auto;
  margin-left: 2rem;
}

.publish-h3 {
  color: var(--gray700);
  margin: 0;
}

.publish-p {
  color: var(--gray600);
}

.publish-highlight {
  color: var(--primary);
  font-weight: 500;
}
