.styled-input {
  padding: 0.6rem 0.9rem;
  width: 100%;
  margin-top: 0.6rem;
  margin-bottom: 1rem;
  border-radius: 4px;
  border: solid 1px var(--gray400);
  font-family: var(--fontFamily);
}

.styled-input:focus {
  border: solid 1px var(--gray800);
  outline: none;
}
.styled-label {
  padding-bottom: 1.5rem;
}

.styled-label b {
  color: var(--red);
}

.input-password {
  position: relative;
}

.input-password div {
  position: absolute;
  right: 1rem;
  top: 0.2rem;
  cursor: pointer;
  transform: translateY(50%);
}

.input-password div path {
  fill: var(--gray500);
  transition: all ease-in-out 0.2s;
}

.input-password div:hover path {
  fill: var(--gray600);
}
