.week-days {
  display: flex;
  justify-content: space-around;
  color: var(--gray600);
  padding-top: 2rem;
  padding-bottom: 1.5rem;
}

.today {
  color: var(--primary) !important;
}

thead .today::before {
  content: 'HOJE, ';
}

.schedule-table {
  width: 100%;
  table-layout: fixed;
  text-align: left;
  margin: -2rem 0;
  margin-top: 0rem;
}

.schedule-table th {
  font-weight: 500;
  color: var(--gray600);
}

.schedule-detail {
  width: 5%;
}

.name-of-days th {
  border-bottom: 1px solid var(--gray300);
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
}

.number-of-days td {
  padding-top: 2rem;
}

.schedule-table td {
  margin-top: 1rem;
}

.schedule-table td div:last-child {
  margin-bottom: 1rem;
}

.schedule-time div {
  margin: 0 !important;
  margin-top: 1rem;
}
.table-div {
  padding-left: -2rem;
  padding-right: -2rem;
  width: 100%;
}

.add-shift {
  height: 3.5rem;
  margin: 10px;
  display: flex;
  text-align: center;
  border-radius: 4px;
  border: 1px dashed var(--gray400);
  color: var(--gray400);
  transition: 0.2s ease-in-out;
  cursor: pointer;
  font-size: 1.4rem;
}

.add-shift span {
  margin: auto;
}
.add-shift:hover {
  background: #f9fafb;
}

.schedule-time span {
  margin: 0.3rem auto;
}

.schedule-time {
  margin: auto;
  border-bottom: 1px solid var(--gray300);
  color: var(--gray600);
  padding-bottom: 1rem;
}

.last-turno {
  border-bottom: none;
}
.schedule-time div {
  display: flex;
  flex-direction: column;
}

.shift {
  border-left: solid 4px var(--primary);
  height: 3.5rem;
  margin: 10px;
  display: flex;
  outline: 1px solid var(--gray200);
  border-radius: 4px;
}

.shift span {
  margin: auto 0.5rem;
  font-size: var(--regular);
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  /*height: var(--regular)*var(--regular)*2; /* Fallback for non-webkit 
    margin: 0 auto;*/
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.announced {
  border-left: solid 4px var(--secondary) !important;
}

.exchanged {
  border-left: solid 4px var(--green) !important;
}

.not-published,
.out-month {
  border-left: solid 4px var(--gray200) !important;
  /*color: var(--gray200) !important;*/
}

.out-of-month {
  color: var(--gray300) !important;
}

.week-division {
  padding-top: 1.5rem !important;
  width: 100%;
}

.not-first-division {
  border-top: 1px solid var(--gray300);
  margin-top: 5rem !important;
}

.name-of-days {
  position: sticky;
  background: #fff;
  top: -3rem;
  z-index: 27;
}

.add-selectable:hover {
  background: none !important;
}

.add-selectable {
  border: 1px dashed var(--gray200);
  color: var(--gray200);
  cursor: default !important;
}

.manager {
  cursor: auto !important;
}

.manager:hover {
  background: none !important;
}

.days-of-month {
  padding: 0 10px;
  color: var(--gray600);
}

.today span {
  padding: 0.5rem;
  margin-left: -0.5rem;
  border-radius: 100%;
  background: #f9fafb;
}
