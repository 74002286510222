.notification {
  display: flex;
}

.notification .icon {
  height: 3rem;
  width: 3rem;
  display: flex;
  border-radius: 100%;
  margin: auto;
  margin-right: 1rem;
}

.notification .icon img {
  margin: auto;
}

.notification .icon-div {
  display: flex;
}

.title-message {
  padding: 2rem 0;
  border-bottom: 1px solid var(--gray100);
  width: 100%;
}

.notification .hour {
  padding: 2rem 0;
  color: var(--gray600);
  border-bottom: 1px solid var(--gray100);
}

.notification .title-message p {
  color: var(--gray600);
  margin-top: 0.5rem;
  margin-bottom: 0;
}

.notification .title-message b {
  color: var(--gray800);
  font-weight: 500;
}

.notification .add {
  background: var(--primary100);
}

.notification .hospital {
  background: #c8f2f2;
}

.notification .heart {
  background: #f6e7f9;
}

.notification .cross-circle {
  background: #f5d9d9;
}

.notification .user-notification {
  background: #f6e7f9;
}
