.modal-background {
  height: 100vh;
  width: 100vw;
  position: absolute;
  background: rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
  z-index: 30;
  display: flex;
}

.visible {
  display: block;
}

.notVisible {
  display: block;
  height: 0;
  overflow: auto;
}

@keyframes ldio-qbcpwz0onen {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.ldio-qbcpwz0onen div {
  position: absolute;
  width: 4rem;
  height: 4rem;
  border: 0.8rem solid #009ffa;
  border-top-color: transparent;
  border-radius: 50%;
}
.ldio-qbcpwz0onen div {
  animation: ldio-qbcpwz0onen 1s linear infinite;
  top: 3rem;
  left: 3rem;
}
.loadingio-spinner-rolling-8z2y65tbbs {
  width: 7rem;
  height: 7rem;
  display: inline-block;
  overflow: hidden;
  background: rgba(NaN, NaN, NaN, 0);
}
.ldio-qbcpwz0onen {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-qbcpwz0onen div {
  box-sizing: content-box;
}
/* generated by https://loading.io/ */

.loading {
  width: 6rem;
  margin: auto;
  display: flex;
}
