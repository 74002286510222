.styled-table {
  width: 100%;
  margin-top: 3rem;
  color: var(--gray500);
}

.styled-table thead th {
  color: var(--gray500);
  font-weight: 500;
  text-align: left;
}

.styled-table tr td {
  padding: 1rem 0;
}

.styled-table .actions {
  display: flex;
  width: auto;
  justify-content: space-around;
}

.styled-table b {
  font-weight: 500;
  color: var(--gray700);
}

.styled-table p {
  margin: 0;
  margin-top: 0.5rem;
}

.styled-table tr {
  transition: 0.2s ease-in-out;
}
/*
.styled-table tr:hover {
    background: var(--background);
}
*/

.styled-table tbody tr .photo {
  background: var(--primary100);
  height: 3rem;
  width: 3rem;
  border-radius: 100%;
  margin: auto;
  display: flex;
}

.styled-table tbody {
  color: var(--gray700);
}
.styled-table tr .photo span {
  margin: auto;
  font-size: 1rem;
  font-weight: 500;
  color: var(--primary);
}
.styled-table .select div {
  display: flex;
}

.styled-table .select div .styled-checkbox {
  margin: auto;
}

.styled-table thead .photo {
  width: 8rem;
  text-align: center;
}
