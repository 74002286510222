#login-logo-adm {
  color: #3c9bff;
  text-align: center;
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-size: 46px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

#adm-badge-logo {
  position: absolute;
  top: -22px;
  right: -28px;
}

#adm-logo-container {
  position: relative;
  height: 30px;
  margin-bottom: 70px;
  padding-bottom: 45px;
}

.login {
  background: linear-gradient(100.12deg, #e5eef3 42.43%, #ffffff 55.82%);
  display: flex;
}
.login-background {
  height: 100vh;
}

.login-background-div {
  min-width: 47vw;
  width: 47vw;
  max-width: 80vw;
  height: 100vh;
  background-position: center 0;
  background-repeat: no-repeat;
  background-size: cover;
}

.login-form {
  margin: auto;
}

.login-form-container {
  width: 20rem;
}

.login-form-container .button {
  cursor: pointer;
  text-decoration: none;
  text-align: right;
  color: var(--primary);
  margin: 0;
  width: fit-content;
  float: right;
}

.login-logo {
  margin: 0% 8% 4rem 8%;
  width: 84%;
}

.login-form h1 {
  color: var(--primary400);
  font-size: 1.8rem;
}

.login-form p {
  color: var(--gray600);
}

.password-text {
  color: var(--gray500) !important;
  margin-top: -0.5rem;
}

.login .styled-button {
  margin-top: 2.5rem;
}

.login .error-msg {
  color: var(--red);
}

.resend-code:hover {
  color: var(--primary);
}

.resend-code {
  transition: all ease-in-out 0.2s;
  cursor: pointer;
}
