.MuiPaginationItem-text {
  color: var(--gray600) !important;
}

.Mui-selected {
  color: #fff !important;
}

.input-with-icon {
  position: relative;
}

.input-with-icon input {
  padding: 0.75rem 1rem;
  padding-right: 3rem;
}
.input-with-icon .input-icon {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
}
