.ra-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  box-sizing: border-box;
  background-color: #f1f1f145;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-top: 40px;
}

@media screen and (max-width: 768px) {
  .ra-container {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
  }
}

@media screen and (max-height: 600px) {
  .ra-container {
    height: 100%;
  }
}

.ra-logo {
  width: 320px;
}

.ra-title {
  font-size: 24px;
  font-weight: bold;
  margin-top: 20px;
}

.ra-explanation-box {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ra-text {
  font-size: 16px;
  margin-top: 0px;
}

.ra-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ra-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px;
  text-align: start;
}

.ra-form-container > input {
  width: 80%;
  height: 50px;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 0 20px;
  margin-top: 10px;
  font-size: 16px;
  margin-bottom: 20px;
}

.ra-form-container > span {
  font-size: 12px;
  color: #df462392;
  align-self: flex-start;
  /* margin-top: 10px; */
  font-weight: bold;
}

.ra-form-container > ul {
  /* margin-top: 10px; */
  font-size: 12px;
  align-self: flex-start;
}

.ra-form > button {
  width: 100%;
  height: 40px;
  border: 0;
  border-radius: 4px;
  padding: 0 20px;
  margin-top: 50px;
  font-size: 16px;
  font-weight: bold;
  background: #df4723;
  color: #fff;
  cursor: pointer;
}

.ra-error {
  display: block;
  font-size: 12px;
  color: #df4723 !important;
  margin-top: 10px;
  font-weight: bold;
  text-align: center;
  margin: 0 auto;
}
