.label-for-professional {
  font-size: 1rem;
  font-weight: 400;
  color: var(--gray700);
  margin-top: 1rem;
  margin-bottom: 0.8rem;
}

.row {
  display: flex;
  margin-top: 3rem;
}

.input-field .hour {
  color: var(--gray500);
  margin: auto 1.4rem;
}

.input-field {
  display: flex;
  flex-direction: column;
}

.input-field label {
  margin-bottom: 0.8rem;
}

.repeat span {
  color: var(--gray500);
  transition: 0.2s ease-in-out;
  cursor: pointer;
  font-weight: 500;
}

.repeat span:hover {
  color: var(--primary);
}

.repeat .selected {
  color: var(--primary);
  padding-bottom: 0.1rem;
  border-bottom: 2px solid var(--primary);
}

.add-week {
  color: var(--primary);
  display: flex;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.add-week span,
.remove-week span {
  margin: auto 0.4rem;
}

.remove-week {
  display: flex;
  color: var(--gray500);
  transition: ease-in-out 0.2s;
  cursor: pointer;
  width: fit-content;
}

.remove-week path {
  fill: var(--gray500);
}

.remove-week svg {
  width: 1.2rem;
}

.remove-week:hover {
  color: var(--red);
}

.remove-week:hover path {
  fill: var(--red);
}

.repeat span:nth-child(2) {
  margin-right: 2rem;
  display: inline-flex;
}

.repeat svg {
  margin-top: auto;
  margin-right: 0.8rem;
  margin-bottom: auto;
}
.week-repeat {
  display: flex;
  margin: 1.5rem 0;
}

.week-repeat span {
  background: var(--gray100);
  color: var(--gray600);
  height: 1.1rem;
  min-width: 1.4rem;
  padding: 0.4rem;
  border-radius: 1.4rem;
  text-align: center;
  margin: 0.2rem;
  transition: 0.2s ease-in-out;
}

.week-repeat .selected {
  background: #009ffa33;
  color: var(--primary);
  padding: 0.4rem 0.8rem;
}

.week-repeat .selected img {
  margin-right: 0.5rem;
}

.turno-option {
  height: 1rem;
}

.conflict-alert {
  color: var(--red);
}

.filled-error {
  background: #f1821c1a;
  padding: 0.8rem 1.3rem;
  margin-bottom: 1.5rem;
  color: var(--red);
  font-weight: 500;
}
