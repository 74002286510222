.modal-background {
  height: 100vh;
  width: 100vw;
  position: absolute;
  background: rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
  z-index: 40;
  display: flex;
}

.visible {
  display: block;
}

.notVisible {
  display: block;
  height: 0;
  overflow: auto;
}
.modal {
  background: #fff;
  border-radius: 8px;
  max-height: 80vh;
  overflow-y: auto;
  margin: 10vh auto;
}

.xlg .modal {
  width: 90vw;
}

.lg .modal {
  width: 80vw;
}

.md .modal {
  width: 50vw;
}

.xmd .modal {
  width: 60vw;
}

.sm .modal {
  width: 40vw;
}

.xsm .modal {
  width: 35vw;
}

.xxsm .modal {
  width: 30vw;
}

.modal-content {
  padding: 0px 100px 70px 100px;
}
.modal-header {
  height: 70px;
  display: flex;
  justify-content: end;
  padding: 0 1rem;
}

.modal-header span {
  margin: auto 0;
  cursor: pointer;
}

.modal-content h2 {
  margin: 0;
  color: var(--gray700);
}
.sub-title {
  color: var(--gray600);
  font-weight: 400;
  margin-top: 0.5rem;
}

.buttons-row {
  width: 100%;
  display: flex;
  justify-content: end;
  padding-top: 4rem;
}

.buttons-row button {
  width: 6rem;
}

.buttons-row button:not(:last-child) {
  margin-right: 1rem;
}

.division {
  width: 100%;
  border-bottom: 1px solid var(--gray300);
  margin: 3rem 0;
}

.image-header {
  height: 140px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: -300px;
}

.image-header span {
  background: rgba(255, 255, 255, 0.5);
  padding: 0.5rem;
  border-radius: 100%;
  display: flex;
  transition: all ease-in-out 0.2s;
}

.image-header span:hover {
  background: rgba(255, 255, 255, 0.75);
}
