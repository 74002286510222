.styled-form .styled-label {
  color: var(--gray700) !important;
}

.styled-form h2 {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 1.8rem;
  margin-top: 0.5rem;
}
