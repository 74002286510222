.add-professional-back {
  fill: var(--gray800);
  transition: all ease-in-out 0.2s;
  font-size: 1rem;
  display: flex;
  margin-bottom: 1rem;
  width: fit-content;
}

.add-professional-back:hover {
  color: var(--primary);
}

.add-professional-back .back-arrow {
  fill: var(--gray800);
  transition: all ease-in-out 0.2s;
}

.add-professional-back:hover .back-arrow {
  fill: var(--primary);
  color: var(--primary);
}

.add-professional-back span {
  margin: auto 0.5rem;
}

.styled-input-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.styled-input-container label {
  margin-bottom: 0.66rem;
  color: var(--gray700) !important;
}

.styled-input-container label b {
  color: var(--red);
}

.styled-input-container input {
  padding: 0.7rem 1rem;
  border: var(--gray400) solid 1px;
  border-radius: 0.2rem;
}

.styled-input-container input:focus {
  border: solid 1px var(--gray800);
  outline: none;
}
