.container {
  background: var(--white);
  padding: 2rem;
  border-radius: 8px;
  margin-bottom: 2rem;
}

.container .header span {
  margin-top: auto;
  margin-bottom: auto;
  font-size: 1.1rem;
  font-weight: 500;
}

.container .header {
  display: flex;
  justify-content: space-between;
}

.container .sub-title {
  color: var(--gray500);
  font-weight: 400 !important;
  font-size: 1rem !important;
  margin-top: 0.2rem;
}

.container .header-form {
  border-bottom: 1px solid var(--gray200);
  display: flex;
}

.container .header-form .selected {
  color: var(--primary);
  border-bottom: 3px solid var(--primary);
  padding-bottom: 0.5rem;
}

.container .sub-header {
  color: var(--gray500);
  font-weight: 400;
  border-bottom: 1px solid var(--gray100);
  padding-top: 3rem;
  padding-bottom: 1.5rem;
}

.default-message h2 {
  color: var(--gray400);
  text-align: center;
  font-weight: normal;
  margin-top: 6rem;
}

.default-message {
  margin-bottom: 6rem;
}
