.month-picker {
  display: flex;
}

.right-arrow img {
  transform: rotate(180deg);
}

.arrow {
  background: var(--primary100);
  border-radius: 4px;
  height: 40px;
  width: 40px;
  display: flex;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.arrow:hover {
  background: var(--primary200);
}
.right-arrow {
  margin-left: 0.6rem;
}

.arrow img {
  margin: auto;
}

.month-picker .month {
  font-size: 1.1rem;
  margin-left: 1.5rem;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 2rem;
  font-weight: 500;
  color: var(--gray700);
  min-width: 140px;
}

.actions {
  margin-right: 2rem;
}

.header .actions button {
}

.actions img {
  cursor: pointer;
}

.week-number {
  color: var(--gray600);
}

.header-container {
  display: flex;
  justify-content: space-between;
}

.schedule-container .container {
  padding: 2rem 0;
}

.dot-menu {
  cursor: pointer;
  display: flex;
}

.dot-menu img {
  margin: auto;
}

.copy-month path {
  fill: var(--gray400);
}

.copy-month svg {
  margin: auto;
}

.copy-month {
  display: flex;
}

.copy-month b {
  font-weight: 900;
  font-size: 0.8rem;
}
