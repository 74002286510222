.sidebar {
  background: var(--white);
  height: 100vh;
  width: 240px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 29;
}

.sidebar .logo {
  width: 60%;
  margin: 12% 20%;
}

.topmenu {
  background: var(--white);
  width: 100vw;
  display: inline-flex;
  justify-content: end;
  box-shadow: 0px 4px 10px 0px #e1e1e140;
  height: 70px;
  position: fixed;
  top: 0;
}

.user {
  display: flex;
  border-left: 1px solid var(--gray200);
  padding: 0rem 2rem;
  color: var(--gray400);
  text-align: right;
  margin: 1rem 1rem;
}

.user b {
  font-weight: 500;
  color: var(--gray700);
  line-height: 1.2rem;
}

.user-image {
  height: 2.3rem;
  background: var(--primary100);
  padding: 0rem;
  width: 2.3rem;
  border-radius: 50%;
  text-align: center;
  display: flex;
  margin-left: 1.5rem;
}

.user-image b {
  color: var(--primary);
  font-size: 1rem;
  line-height: 1rem;
  margin: auto;
}

.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 4rem;
}

.sidebar li {
  font-weight: 400;
  margin: 3% 5%;
  padding: 0.5rem 1.5rem;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.menu-item a {
  color: var(--gray400);
}
.sidebar a:hover {
  color: var(--primary);
}
.sidebar .active li {
  background: var(--primary);
  box-shadow: 0px 2px 4px 0px #8a92a64d;
}

.active a {
  color: var(--white) !important;
}
.menu-item img {
  margin-right: 1.5rem;
  width: 24px;
  height: 24px;
}

.sidebar li a {
  display: flex;
  text-decoration: none;
}

.sidebar a {
  text-decoration: none;
}

.sidebar span {
  margin: auto 0;
}

#pages {
  padding: 3rem;
  margin-left: 240px;
  margin-top: 70px;
  /*width: calc(100vw - 270px - 6rem);*/
  height: calc(100vh - 70px - 6rem);
  overflow-y: scroll;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--gray100);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--gray400);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--gray500);
}

.info {
  height: fit-content;
  margin: auto;
}

.logout {
  color: var(--gray400);
  cursor: pointer !important;
  transition: 0.2s ease-in-out;
  position: absolute;
  bottom: 2rem;
  left: 1rem;
}

.logout:hover {
  color: var(--primary);
}

.topmenu .medical-group {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.topmenu .medical-group .selected-group {
  display: flex;
  align-items: center;
  height: 2rem;
  margin: auto;
  padding: 0 0.77rem;
  border-radius: 0.44rem;
  width: 12rem;
  width: auto;
  background: #f9fafb;
  cursor: pointer;
}

.topmenu .medical-group .empty-selected-group {
  display: flex;
  align-items: center;
  height: 2rem;
  margin: auto;
  padding: 0 0.5rem;
  border-radius: 0.2rem;
  width: 12rem;
  width: auto;
  background: rgb(240, 240, 240);
}

.sidebar .turnos-division {
  border-top: var(--gray200) 1px solid;
  margin: 1rem 0.6rem;
}

.user,
.medical-group {
  position: relative;
}
.user:hover .user-submenu {
  max-height: 500px;
  border-top: solid 1px var(--gray100);
}

.medical-group:hover .medicalgroup-submenu {
  max-height: 1500px;
  border-top: solid 1px var(--gray100);
  padding-bottom: 0.5rem;
  max-height: 21rem;
  overflow: auto;
}

.user-submenu,
.medicalgroup-submenu {
  max-height: 0;
  transition: all 0.4s ease-in-out;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 50px;
  width: calc(100% + 0.5rem);
  text-align: left;
  background: #fff;
  border-radius: 0 0 0.5rem 0.5rem;
  display: flex;
  flex-direction: column;
  border-top: solid 1px #fff;
}

.user-submenu span,
.medicalgroup-submenu span {
  padding: 0.5rem 2rem !important;
  cursor: pointer;
  transition: all ease-in-out 0.2s;
  margin: 0.3rem !important;
  color: var(--gray700);
  border-radius: 0.5rem;
}

.user-submenu span:hover,
.medicalgroup-submenu span:hover {
  background: #f9fafb;
}
.user-submenu .log-out {
  color: var(--gray500);
}
.user-submenu .log-out:hover {
  color: var(--red);
}
.user-submenu span:hover,
.medicalgroup-submenu span:hover {
  color: var(--primary);
}

.medicalgroup-submenu {
  top: 66px;
  min-width: max-content;
}

.medical-group:hover .medicalgroup-submenu,
.user:hover .user-submenu {
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
}

.topmenu {
  z-index: 28;
}

.change-password {
  color: var(--primary);
  transition: all ease-in-out 0.2s;
  cursor: pointer;
}

.new-password {
  background-color: var(--primary100);
  padding: 0.5rem;
  margin-bottom: 1rem;
  color: var(--primary);
  font-weight: 600;
}

.group-alert {
  background: #f1821c1a;
  padding: 1rem 1.5rem;
  font-weight: 500;
  color: #f1821c;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}

.group-alert a {
  text-decoration: none;
  color: var(--primary);
  transition: all ease-in-out 0.2s;
}

.group-alert a:hover {
  border-bottom: 1px solid var(--primary);
}
