.chalendar-result {
  display: inline-block;
  padding: 3rem;
  border-radius: 1rem;
  float: right;

  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='rgb(0, 159, 250)' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.chalendar-result h3 {
  margin: 0;
  margin-bottom: 3rem;
  color: var(--gray700);
}

.chalendar-result .month {
  color: var(--gray800);
  font-weight: 600;
  font-size: 1rem;
}
.chalendar-result table td {
  text-align: center !important;
  color: var(--gray600);
  height: 2rem;
  width: 2rem;
}

.chalendar-result table .other-month {
  color: var(--gray500);
}

.chalendar-result .day {
  text-align: center !important;
  border-radius: 100%;
  padding: 0.6rem;
  margin: 0.1rem;
}

.chalendar-result .selected {
  background-color: var(--primary);
  color: #fff;
  transition: 0.2s ease-in-out;
}
.chalendar-result .days-of-week {
  color: var(--gray500) !important;
}

.chalendar-result .month {
  text-align: center;
}

.current-month {
  cursor: pointer;
  transition: all ease-in-out 0.2s;
}

.current-month:hover {
  background: var(--primary100);
  border-radius: 100%;
}

.select-message {
  color: var(--gray600);
  font-weight: 400;
}
