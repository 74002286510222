.styled-button {
  width: 100%;
  padding: 0.8rem;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 0.3rem;
  transition: 0.2s ease-in-out;
  font-family: 'Inter', sans-serif;
}

/*  PRIMARY  */
.primary {
  color: var(--white);
  background: var(--primary);
}
.primary:hover {
  background: var(--primaryAccent);
}
.primary:disabled {
  background: var(--gray100) !important;
  color: var(--gray600) !important;
  cursor: default;
}

.primary:disabled path {
  fill: var(--gray600) !important;
}

.primary:disabled:hover {
  background: var(--gray200);
}

/*  SECONDARY  */
.secondary {
  background: none;
  border: 1px solid var(--primary);
  color: var(--primary);
}

.default {
  background: none;
  color: var(--primary);
}

.default-danger {
  background: none;
  color: var(--red);
}
.default-danger:hover {
  color: var(--redAccent);
}

.secondary:hover {
  background: none;
  border: 1px solid var(--primaryAccent);
  color: var(--primaryAccent);
}

.secondary:disabled,
.destructive:disabled {
  background: none;
  border: 1px solid var(--gray100);
  color: var(--gray600);
}

.secondary:disabled:hover,
.destructive:disabled:hover {
  background: none;
  border: 1px solid var(--gray600);
  color: var(--gray700);
}

/*  DESTRUCTIVE */
.destructive {
  background: var(--destructive-bg);
  color: var(--red);
}

.destructive:hover {
  background: var(--destructive-bg-hover);
  color: var(--redAccent);
}
